import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import ServerEmail from '~/apps/ServerEmail';
import LoadingScene from '~/components/MFEProvider/lib/ReactSuspense/LoadingScene';
import Hosting from '~/scenes/Hosting';
import Overview from '~/scenes/Hosting/scenes/HostingDetail/scenes/Overview';

import { getIsDevMode } from './components/DevMode';
import useFeature from './hooks/useFeature';
import useQueryFeature from './hooks/useQueryFeature';
import HostingList from './scenes/HostingList';

const Resource = lazy(() => import('~/apps/ResourceUsage'));
const SslManager = lazy(() => import('~/apps/SSLManager'));
const Redirects = lazy(() => import('~/apps/Redirects'));
const Backups = lazy(() => import('~/apps/Backups'));
const Databases = lazy(() => import('~/apps/Databases'));
const Sites = lazy(() => import('~/scenes/Sites'));
const SSHKeyManager = lazy(() => import('~/apps/SSHKeyManager'));
const ProServices = lazy(() => import('~/apps/ProServices'));
const CronJobs = lazy(() => import('~/apps/CronJobs'));
const FtpAccessManger = lazy(() => import('~/apps/FTPAccess'));
const Indexes = lazy(() => import('~/apps/Indexes'));
const Security = lazy(
  () => import('~/scenes/Hosting/scenes/HostingDetail/scenes/Security'),
);
const Resources = lazy(
  () =>
    import(
      './scenes/Hosting/scenes/HostingDetail/scenes/Overview/Cloud/scenes/Resources'
    ),
);
const ChooseDomain = lazy(
  () =>
    import(
      './scenes/Hosting/scenes/HostingDetail/components/AdvancedHosting/components/Server/ChooseDomain'
    ),
);

const PhpInfo = lazy(() => import('~/apps/Phpinfo'));
const Logs = lazy(() => import('~/apps/Logs'));
const DirectoryPrivacy = lazy(() => import('~/apps/DirectoryPrivacy'));

const MainRoutes = () => {
  const [isAdvancedHosting] = useFeature('advanced_hosting');
  const [isDestiny] = useFeature('destiny');
  const [enableHostingTabs] = useQueryFeature('hostingtabs');

  return (
    <Suspense>
      <Routes>
        <Route path="/*">
          {/* each of these need to implement a TenantValidation */}
          {/* each of these need to be wrapped in HostingProvider */}
          <Route element={<Hosting />}>
            <Route index element={<Overview />} />
            <Route path="ssl-manager/*" element={<SslManager />} />
            <Route path="cron-jobs/*" element={<CronJobs />} />
            <Route path="ssh-keys/*" element={<SSHKeyManager />} />
            <Route path="pro-services/*" element={<ProServices />} />
            <Route path="ftp-access/*" element={<FtpAccessManger />} />
            <Route path="databases/*" element={<Databases />} />
            <Route path="redirects/*" element={<Redirects />} />
            <Route path="email/*" element={<ServerEmail />} />
            <Route path="resources/*" element={<Resources />} />
            <Route path="backups/*" element={<Backups />} />
            <Route path="phpinfo/:siteID/*" element={<PhpInfo />} />
            {(isDestiny || enableHostingTabs) && (
              <Route path="security/*" element={<Security />} />
            )}
            <Route path="logs/*" element={<Logs />} />
            <Route path="directory-privacy/*" element={<DirectoryPrivacy />} />
            <Route path="indexes/*" element={<Indexes />} />
            <Route path="resource-usage/*" element={<Resource />} />
          </Route>

          {/* each of these need to implement a TenantValidation */}
          {/* sub-routes of Sites need to be wrapped in HostingProvider at the SiteDetail level */}
          <Route path="sites/*" element={<Sites />} />
          {getIsDevMode(['development', 'beta']) && (
            <Route path="hosting/*">
              <Route index element={<HostingList />} />
              <Route path=":hostingId" element={<Overview />} />
            </Route>
          )}

          {isAdvancedHosting && (
            <Route path="setup-server/*" element={<ChooseDomain />} />
          )}

          {/* No validation needed */}
          <Route path="*" element={<LoadingScene />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default MainRoutes;
