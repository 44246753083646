import {
  HostingSitesV2200ItemsItem,
  SiteInfoResponseModelV2,
  SitesInfo200,
} from '@newfold/huapi-js/src/index.schemas';

export const checkIsStagingSite = (
  site?: SitesInfo200 | SiteInfoResponseModelV2 | HostingSitesV2200ItemsItem,
) =>
  !!site &&
  (!!site?.staging?.parent_site_id || !!site?.staging?.promoting_from) &&
  !!site?.staging?.promotion_status;

export const checkIsParentSite = (
  site?: SitesInfo200 | SiteInfoResponseModelV2 | HostingSitesV2200ItemsItem,
) =>
  !!site &&
  !site?.staging?.parent_site_id &&
  !site?.staging?.promoting_from &&
  !site?.staging?.promotion_status &&
  // Was this a staging site previously?
  (!!site?.staging?.promoted_from ||
    // Does this site currently have staging sites?
    (!!site?.staging?.sites && site?.staging?.sites.length > 0));
