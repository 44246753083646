import { PropsWithChildren } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import CopyButton from '~/scenes/Site/scenes/Settings/components/CopyButton';

interface ViewIpModalTypes {
  openIPModal?: boolean;
  setOpenIPModal?: Function;
  ips?: any;
}

const AdvancedHostingViewIPModal = ({
  openIPModal = false,
  setOpenIPModal = undefined,
  ips = undefined,
}: PropsWithChildren<ViewIpModalTypes>) => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'nameServerAndIPCard.ipAddress.modal',
  });

  const handleClose = (reason: any) => {
    if (reason && reason === 'backdropClick') return;
    /* @ts-ignore */
    setOpenIPModal(false);
  };

  return (
    <Dialog open={openIPModal} fullWidth maxWidth="sm">
      <DialogTitle>{t('title')}</DialogTitle>
      <Divider variant="middle" />
      <DialogContent>
        <Stack spacing={3}>
          <Typography>
            <Trans i18nKey="settings:nameServerAndIPCard.ipAddress.modal.desc" />
          </Typography>
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                {ips?.map((ip: string) => (
                  <TableRow key={ip}>
                    <TableCell component="th" scope="row">
                      <Typography variant="body1">{ip}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <CopyButton disable={!ip} text={ip} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          {t('closeBtn')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdvancedHostingViewIPModal;
