import { useQueryClient } from '@tanstack/react-query';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import WarningIcon from '@mui/icons-material/Warning';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  getSitesInfoQueryKey,
  useSitesDomainsV2,
  useSiteUpdate,
} from '@newfold/huapi-js';
import { SitesDomainsV2Params } from '@newfold/huapi-js/src/index.schemas';

import useAlerts from '~/components/Alerts/alertsStore';
import { useBrandInfo } from '~/hooks/useBrandInfo';

const ChangeSiteURLModal = ({
  domain = undefined,
  open = false,
  setOpen,
  siteId = undefined,
  isRetry = false,
}: PropsWithChildren<{
  domain?: string;
  open?: boolean;
  setOpen: Function;
  siteId?: number;
  isRetry?: boolean;
}>) => {
  const { t } = useTranslation('domains');
  const [, { generateAlert }] = useAlerts();
  const { siteId: paramsSiteId } = useParams();
  const siteIdNum: number = siteId ?? Number(paramsSiteId);
  const queryClient = useQueryClient();
  const { phoneNumberFormatted } = useBrandInfo();
  const descriptionKey = isRetry ? 'retryDescription' : 'description';
  const titleKey = isRetry ? 'retryTitle' : 'title';

  const [isPollingDomains, setIsPollingDomains] = useState(false);
  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  const sitesInfoQueryKey = getSitesInfoQueryKey(siteIdNum);

  const queryParams: SitesDomainsV2Params = {};
  const {
    data: siteDomainsInfo,
    refetch: refetchDomains,
    isSuccess: isSuccessSiteDomains,
  } = useSitesDomainsV2(siteIdNum, queryParams, {
    query: {
      enabled: !!siteIdNum && isPollingDomains,
      // refetch Domain list on update success until domain is found in the list and is active
      refetchInterval: (query) => {
        const queryStateData = query?.state?.data;
        const domains = queryStateData?.data?.domains;
        const domainStatus = domains?.find((d) => d.domain === domain)?.status;
        const keepPolling = isPollingDomains && domainStatus !== 'active';
        return keepPolling ? 5000 : false;
      },
    },
  });

  // onSuccess - useSitesDomainsV2
  useEffect(() => {
    if (isSuccessSiteDomains && isPollingDomains) {
      const domains = siteDomainsInfo?.data?.domains;
      const domainStatus = domains?.find((d) => d.domain === domain)?.status;
      const siteUrlUpdated = domainStatus === 'active';

      if (siteUrlUpdated) {
        generateAlert({
          description: (
            <Trans
              i18nKey="domains:changeSiteURLModal.successMessage"
              values={{ url: domain }}
              components={{ b: <b /> }}
            />
          ),
          severity: 'success',
          showCloseBtn: true,
        });
        setIsPollingDomains(false);
        handleClose();
        queryClient.invalidateQueries({ queryKey: sitesInfoQueryKey });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessSiteDomains, siteDomainsInfo, isPollingDomains]);

  const { mutate: updateSiteUrl, isPending } = useSiteUpdate({
    mutation: {
      onError: () => {
        generateAlert({
          description: (
            <Trans
              i18nKey="domains:changeSiteURLModal.errorMessage"
              values={{ phone: phoneNumberFormatted }}
              components={{ b: <b /> }}
            />
          ),
          severity: 'error',
          showCloseBtn: true,
        });
        handleClose();
      },
      onSuccess: () => {
        setIsPollingDomains(true);
        refetchDomains();
      },
    },
  });

  const handleConfirm = () => {
    updateSiteUrl({
      siteId: siteIdNum,
      data: { domain, skip_fg_checks: true },
    });
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="change-site-url-modal-title"
      aria-describedby="change-site-url-modal-description"
      data-testid="change-site-url-modal"
    >
      <DialogContent>
        <Stack direction="row" alignItems="center">
          <WarningIcon color="error" />
          <DialogTitle>{t(`changeSiteURLModal.${titleKey}`)}</DialogTitle>
        </Stack>
        <Divider />
        <Typography variant="body1" py={3}>
          <Trans
            i18nKey={`domains:changeSiteURLModal.${descriptionKey}`}
            values={{ url: domain }}
            components={{ b: <b /> }}
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button
            variant="text"
            onClick={handleClose}
            data-testid="change-site-url-cancel-btn"
          >
            {t('changeSiteURLModal.cancelBtn')}
          </Button>
          <LoadingButton
            variant="contained"
            onClick={handleConfirm}
            loading={isPending || isPollingDomains}
            data-testid="change-site-url-confirm-btn"
          >
            {t('changeSiteURLModal.confirmBtn')}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeSiteURLModal;
