// import StorageIcon from '@mui/icons-material/Storage';
import { useQueryClient } from '@tanstack/react-query';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { getHostingSitesV2QueryKey, useSite } from '@newfold/huapi-js';
import {
  HostingSitesV2200ItemsItem,
  SiteInfoResponseModelV2,
} from '@newfold/huapi-js/src/index.schemas';

import { useAlert } from '~/components/Alerts/alertsStore';
import LoadingButtonWrapper from '~/components/LoadingButtonWrapper';
import MoreActionsMenu from '~/components/MoreActionsMenu';
import MoreActionsItem from '~/components/MoreActionsMenu/components/MoreActionsItem';
import SsoLink from '~/components/SsoLink';
import useAccount from '~/hooks/useAccount';
import useHostingInfo from '~/hooks/useHostingInfo';
import DeleteSiteModal from '~/scenes/Site/scenes/Cloud/components/DeleteSiteFork/DeleteSiteModal';
import { checkIsStagingSite } from '~/scenes/Site/scenes/Cloud/scenes/Staging/utils/checkIsStagingOrParentSite';
import WordPressIcon from '~/scenes/Sites/assets/icons/WordPressIcon';
import { getSiteDisabled } from '~/utils/getSiteDisabled';

const installingStatuses = ['pending_create', 'create'];
const failedInstallingStatuses = ['failed_create'];
const configuringStatuses = ['pending_configure', 'configure'];
const failedConfigureStatus = ['failed_configure'];
const deletingStatuses = ['pending_delete', 'delete'];

export default function SiteCardActions({
  site,
}: PropsWithChildren<{
  site: HostingSitesV2200ItemsItem | SiteInfoResponseModelV2;
}>) {
  const { t } = useTranslation('sites');
  const { isDisabled: isHostingDisabled } = useHostingInfo();
  const { id: hostingId } = useAccount();
  const generateAlert = useAlert();

  const queryClient = useQueryClient();
  const queryKey = getHostingSitesV2QueryKey(hostingId);

  const [openDeleteSiteModal, setOpenDeleteSiteModal] = useState(false);
  const showDeleteSiteModal = () => setOpenDeleteSiteModal(true);

  const hasUrlOrPendingUrl: boolean = !!site?.url || !!site?.pending_url;

  const iconButtonRef = useRef<HTMLButtonElement | null>(null);

  // Can be improved later when handling more than one site.
  const actionDelete = localStorage.getItem('action');

  useEffect(() => {
    if (!!actionDelete && actionDelete === 'showDeleteSite') {
      iconButtonRef?.current?.click();
      localStorage.removeItem('action');
    }
  }, [actionDelete]);

  const { mutate: deleteSite, isPending: isPendingSiteDeletion } = useSite({
    mutation: {
      onSuccess: () => {
        generateAlert({
          severity: 'success',
          description: t('cloud.deleteSite.alertMessage.success', {
            siteName: site?.name,
          }),
          showCloseBtn: true,
        });
      },
      onError: () => {
        generateAlert({
          severity: 'error',
          description: t('cloud.deleteSite.alertMessage.error'),
          showCloseBtn: true,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey });
      },
    },
  });

  const handleSubmit = (event: any) => {
    deleteSite({ siteId: Number(site?.id!), data: {} });
  };

  const siteId = site?.id;
  const siteStatus = site?.status!;
  const isMigration = !!site?.migration_id;
  const isSiteDisabled = getSiteDisabled(siteStatus);
  const isStagingSite = checkIsStagingSite(site);

  const disableSSO = isHostingDisabled || !siteId || isSiteDisabled;

  // Migration Site Failed
  if (isMigration && failedInstallingStatuses.includes(siteStatus))
    return (
      <Stack direction="row" spacing={2}>
        <Alert
          severity="info"
          // @ts-ignore
          type="simple"
          icon={<CircularProgress size={20} />}
        >
          <Typography variant="body2">
            {t('disableSite.migratingTxt')}
          </Typography>
        </Alert>
        <LoadingButtonWrapper
          variant="outlined"
          color="error"
          loading={isPendingSiteDeletion}
          onClick={handleSubmit}
        >
          {t('cloud.deleteSite.btnTxt')}
        </LoadingButtonWrapper>
      </Stack>
    );

  // Migrating Site
  if (isMigration && installingStatuses.includes(siteStatus))
    return (
      <Stack spacing={2} alignItems={'start'}>
        <Alert
          severity="info"
          // @ts-ignore
          type="simple"
          icon={<CircularProgress size={20} />}
        >
          <Typography variant="body2">
            {t('disableSite.migratingTxt')}
          </Typography>
        </Alert>
      </Stack>
    );

  // Configure Site Failed
  if (failedConfigureStatus.includes(siteStatus))
    return (
      <Stack direction="row" spacing={2}>
        <Alert
          severity="warning"
          // @ts-ignore
          type="simple"
          icon={<WarningIcon fontSize="small" />}
        >
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="body2">
              {t('disableSite.configureFailed')}
            </Typography>
            <Tooltip
              title={t('disableSite.failedConfigureToolTip')}
              arrow
              placement="top-start"
            >
              {/* @ts-ignore */}
              <InfoIcon fontSize="small" />
            </Tooltip>
          </Stack>
        </Alert>
      </Stack>
    );

  // Configuring Site
  if (configuringStatuses.includes(siteStatus))
    return (
      <Stack spacing={2} alignItems={'start'}>
        <Alert
          severity="info"
          //@ts-ignore
          type="simple"
          icon={<CircularProgress size={20} />}
        >
          <Typography variant="body2">
            {t('disableSite.configuring')}
          </Typography>
        </Alert>
      </Stack>
    );

  // Deleting Site
  if (deletingStatuses.includes(siteStatus))
    return (
      <Stack spacing={2} alignItems={'start'}>
        <Alert
          severity="error"
          //@ts-ignore
          type="simple"
          icon={<CircularProgress size={20} color="error" />}
        >
          <Typography variant="body2" color={'secondary'}>
            {t('disableSite.deleting')}
          </Typography>
        </Alert>
      </Stack>
    );

  // Delete Site Failed
  if (siteStatus.includes('failed_delete'))
    return (
      <Stack direction="row" spacing={2}>
        <Alert
          severity="error"
          // @ts-ignore
          type="simple"
          icon={<WarningIcon fontSize="small" />}
        >
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="body2">
              {t('disableSite.deleteFailed')}
            </Typography>
            <Tooltip
              title={t('disableSite.failedDeleteToolTip')}
              arrow
              placement="top-start"
            >
              {/* @ts-ignore */}
              <InfoIcon fontSize="small" />
            </Tooltip>
          </Stack>
        </Alert>
        <LoadingButtonWrapper
          variant="outlined"
          color="error"
          loading={isPendingSiteDeletion}
          onClick={handleSubmit}
        >
          {t('cloud.deleteSite.btnTxt')}
        </LoadingButtonWrapper>
      </Stack>
    );

  // Create Site Failed
  if (failedInstallingStatuses.includes(siteStatus))
    return (
      <Stack direction="row" spacing={2}>
        <Alert
          severity="info"
          //@ts-ignore
          type="simple"
          icon={<CircularProgress size={20} />}
        >
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="body2">
              {t('disableSite.installingWordPress')}
            </Typography>
            <Tooltip
              title={t('disableSite.failedCreateToolTip')}
              arrow
              placement="top-start"
            >
              {/* @ts-ignore */}
              <InfoIcon fontSize="small" />
            </Tooltip>
          </Stack>
        </Alert>
        <LoadingButtonWrapper
          variant="outlined"
          color="error"
          loading={isPendingSiteDeletion}
          onClick={handleSubmit}
        >
          {t('cloud.deleteSite.btnTxt')}
        </LoadingButtonWrapper>
      </Stack>
    );

  // Creating Site
  if (siteStatus?.includes('create'))
    return (
      <Stack spacing={2} alignItems={'start'}>
        <Alert
          severity="info"
          //@ts-ignore
          type="simple"
          icon={<CircularProgress size={20} />}
        >
          <Typography variant="body2">
            {t('disableSite.installingWordPress')}
          </Typography>
        </Alert>
      </Stack>
    );

  // Active Site - staging
  if (isStagingSite) {
    const isPromoting = site?.staging?.promotion_status === 'in_progress';

    return (
      <Stack direction={'row'} spacing={1}>
        {/* 'Promote' button that opens the promote flow modal */}
        <Button
          aria-label={'promote staging site'}
          data-testid={'staging-promote-button'}
          sx={{ flex: { xs: 1, sm: 'auto' } }}
          variant="outlined"
          onClick={() => {}} // TODO: implement when available
          disabled={isHostingDisabled || isPromoting}
        >
          {t('cloud.staging.promoteBtnText')}
        </Button>
        {/* 'Manage' button that takes you to that site's Overview */}
        <Button
          aria-label={'manage staging site'}
          data-testid={'staging-manage-button'}
          sx={{ flex: { xs: 1, sm: 'auto' } }}
          variant="contained"
          href={siteId ? `/sites/${siteId}` : undefined}
          disabled={!siteId || isHostingDisabled}
        >
          {t('cloud.staging.manageBtnText')}
        </Button>
        {openDeleteSiteModal && (
          <Dialog
            open={openDeleteSiteModal}
            aria-labelledby="delete-site-modal-title"
            aria-describedby="delete-site-modal-description"
            maxWidth={'sm'}
            fullWidth
          >
            <DeleteSiteModal
              open={openDeleteSiteModal}
              setOpen={setOpenDeleteSiteModal}
              siteUrl={
                (hasUrlOrPendingUrl && (site?.pending_url || site?.url)) ||
                t('deleteSite.deleteSiteBtn').toUpperCase()
              }
              siteId={site?.id as number}
              siteName={site?.name!}
              parentSiteId={site?.staging?.parent_site_id!}
            />
          </Dialog>
        )}
        {/* Hamburger button for additional actions */}
        {/* This menu should only include options for Delete Site and WPAdmin SSO for now */}
        <MoreActionsMenu buttonRef={iconButtonRef}>
          <MoreActionsItem
            aria-label={'delete staging site'}
            label={t('cloud.staging.deleteBtnText')}
            dataTestId={'staging-delete-button'}
            disabled={isHostingDisabled}
            onClick={showDeleteSiteModal}
          ></MoreActionsItem>
          <SsoLink
            aria-label={'sso to staging site wordpress admin'}
            dataTestId={'staging-sso-button'}
            type="site"
            variant="menuItem"
            disabled={disableSSO}
            id={siteId}
            // @ts-expect-error
            sx={{ flex: { xs: 1, sm: 'auto' } }}
          >
            {t('cloud.staging.wpAdminBtnText')}
          </SsoLink>
        </MoreActionsMenu>
      </Stack>
    );
  }

  // Active Site - production
  return (
    <Stack direction={'row'} spacing={1}>
      <Button
        aria-label={'manage site'}
        data-testid={'prod-manage-button'}
        sx={{ flex: { xs: 1, sm: 'auto' } }}
        variant="outlined"
        href={siteId ? `sites/${siteId}` : undefined}
        disabled={isHostingDisabled}
      >
        {t('cloud.manageBtnText')}
      </Button>

      {/* wordpress sso button */}
      <SsoLink
        aria-label={'sso to site wordpress admin'}
        data-testid={'prod-sso-button'}
        type="site"
        variant="contained"
        disabled={disableSSO}
        id={siteId}
        // @ts-expect-error
        sx={{ flex: { xs: 1, sm: 'auto' } }}
        startIcon={<WordPressIcon color="#fff" />}
      >
        {t('cloud.wpAdminBtnText')}
      </SsoLink>
    </Stack>
  );
}
