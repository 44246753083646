import { useQueryClient } from '@tanstack/react-query';
import {
  arrayOf,
  bool,
  func,
  node,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import Typography from '@mui/material/Typography';

import { useNewfoldBrandTheme } from '~/components/MFEProvider/lib/MUI/themeDefault';
import { useBrandInfo } from '~/hooks/useBrandInfo';
import {
  productTypes,
  setCtbPurchaseQueryData,
  useCtbProductInfo,
  useCtbPurchaseStatusQuery,
} from '~/hooks/useCtbProductInfo';
import useHostingInfo from '~/hooks/useHostingInfo';

import useAlerts from '../Alerts/alertsStore';
import { useMFEContext } from '../MFEProvider';
import Embed from './Embed';
import getCTBEnv from './getCTBEnv';
import includeCTB, { removeCTB } from './includeCTB';

/*
  Component for ClickToBuy functionality for various supported products
  https://confluence.newfold.com/display/SF/CTB+Integration+Guide
*/

const propTypes = {
  open: bool, // controls CTB modal visibility
  product: oneOf(Object.keys(productTypes)).isRequired, // product to be purchased; see product-specific docs

  refetchData: func, // callback to refresh upgraded product info
  currentPlan: string, // initial product plan/level/tier

  // data object to be forwarded to the target CTB instance
  data: shape({
    purchaseTerm: string,
    purchaseTermUnit: string,
    parentProduct: shape({
      // Specifically added for site-based upgrades; currently only Yoast
      siteId: string,
    }),
    parentProdInstId: string, // used for products that are attached to a parent (such as the hosting)
    prodInstId: string, // Only used for hosting upgrades
    action: string, // Only used for hosting upgrades
  }),
  disabled: bool,
  hasPurchasedRecentlyTypographyProps: shape({
    variant: string,
    fontWeight: number,
  }),

  children: oneOfType([arrayOf(node), node]),
};

const CTB = ({
  open: openProp = false,
  product,

  refetchData = undefined,
  currentPlan = undefined,

  // we will forward the data object to the target CTB instance
  data = {},

  disabled = false,

  children = undefined,
  hasPurchasedRecentlyTypographyProps = {},

  // rest of props will be forwarded to the Link
  ...props
}) => {
  const { t } = useTranslation('clickToBuy', { useSuspense: false });
  const [, { generateAlert }] = useAlerts();

  const { isDreamscape } = useBrandInfo();

  // some black magic marketing stuff, see HOST-3192 for details
  // check query params for channelid, ex: channelid=P181C46097236S500N0B11A151D38E0000V111
  const [searchParams] = useSearchParams();
  // we need to send this into the ctb as sessionBannerId
  const sessionBannerId = searchParams.get('channelid');

  const hashFragment = '#click-to-buy-' + product;
  const { hash, pathname } = useLocation();

  const navigate = useNavigate();

  const [open, setOpen] = useState(openProp);
  const [ctbScriptIsIncluded, setCtbScriptIsIncluded] = useState(false);

  const [isPending, setIsPending] = useState(true);

  const { isDisabled: isHostingDisabled, isSuspended } = useHostingInfo();
  const isDisabled = isHostingDisabled || disabled;

  const handleClose = () => {
    setOpen(false);
    // Fix for ctb deeplink email marketing campaign.
    if (hash.includes(hashFragment)) navigate(pathname);
  };

  const {
    accessToken,
    /* We want to use the default refresh callback from AMM here instead of our
      overloaded one, since the CTB shouldn't be setting a new token in our app */
    callbacks: { refreshAccessToken, handleClickToBuy },
    brandFromJWT: brand,
  } = useMFEContext();
  const { env } = getCTBEnv(accessToken);
  const productInfo = useCtbProductInfo(product);
  const queryClient = useQueryClient();

  const theme = useNewfoldBrandTheme();

  const { hasPurchasedRecently, isLoading } = useCtbPurchaseStatusQuery(
    productInfo.purchaseQueryKey,
    // refetchData // eventually refetch actual product info
  );

  useEffect(() => {
    if (accessToken) {
      includeCTB(accessToken);
      setCtbScriptIsIncluded(true);
    }

    return () => {
      setCtbScriptIsIncluded(false);
      removeCTB();
    };
  }, [accessToken]);

  const handleOpenCtbModal = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const closeCtbModal = () => {
    return handleClose();
  };

  const checkOrderDetails = (orderId) => {
    setCtbPurchaseQueryData(queryClient, productInfo.purchaseQueryKey, {
      hasPurchasedRecently: true,
    });
    generateAlert({
      description: t('alerts.upgradeSuccess', {
        product: productInfo.name,
      }),
      severity: 'success',
      showCloseBtn: true,
    });
  };

  const setLoader = (loadingStatus) => {
    setIsPending(loadingStatus);
  };

  const handleInitErrors = (initError) => {
    closeCtbModal();
    generateAlert({
      description: t('alerts.initError', {
        product: productInfo.name,
      }),
      severity: 'error',
      showCloseBtn: true,
      autoHideDuration: 10000,
    });
  };

  const ctbCallbacks = {
    closeCtbModal,
    refreshAccessToken,
    checkOrderDetails,
    setLoader,
    handleInitErrors,
  };

  const ctbData = {
    // ALWAYS pass the following values
    accessToken,
    brand,
    env,
    sessionBannerId,

    // CONDITIONALLY apply default data for the given product
    // check that the product exists
    ...(productInfo
      ? // if so spread its values into this object
        productInfo.data
      : // else spread an empty object (nothing)
        {}),

    // ALLOW <CTB /> CONSUMER to apply any overrides
    ...data,
  };

  useEffect(() => {
    if (
      ctbScriptIsIncluded &&
      !hasPurchasedRecently &&
      !isLoading &&
      hash.includes(hashFragment)
    ) {
      setOpen(true);
    }
  }, [
    ctbScriptIsIncluded,
    hasPurchasedRecently,
    hash,
    isLoading,
    hashFragment,
  ]);

  // OVERRIDE if handler is provided
  if (
    handleClickToBuy &&
    handleClickToBuy[product] &&
    typeof handleClickToBuy[product] === 'function'
  ) {
    return (
      <Link
        id={hashFragment}
        href={hashFragment}
        onClick={handleClickToBuy[product]}
        data-testid={`${product}-ctb-link`}
        // forward rest of props (sx, component, etc.)
        {...props}
        disabled={isSuspended || isDisabled}
      >
        {children}
      </Link>
    );
  }

  // If no handleClickToBuy callback is provided for Dreamscape, return null
  if (isDreamscape) return null;

  // Jarvis Global CTB
  return (
    <>
      {/* return children as links */}
      {children && !hasPurchasedRecently && (
        <Link
          id={hashFragment}
          href={hashFragment}
          onClick={handleOpenCtbModal}
          data-testid={`${product}-ctb-link`}
          // forward rest of props (sx, component, etc.)
          {...props}
          disabled={isSuspended || isDisabled}
        >
          {children}
        </Link>
      )}
      {hasPurchasedRecently && (
        <Typography
          noWrap
          color={'text.disabled'}
          variant="body2"
          {...hasPurchasedRecentlyTypographyProps}
        >
          <Stack
            direction={'row'}
            spacing={1}
            data-testid={`${product}-ctb-upgrading`}
            alignItems={'center'}
            component={'span'}
          >
            <CircularProgress size={16} color="inherit"></CircularProgress>
            <span>{t('labels.upgrading')}</span>
          </Stack>
        </Typography>
      )}

      {open && (
        <ThemeProvider theme={theme}>
          <Dialog
            maxWidth="xl"
            onClose={closeCtbModal}
            aria-labelledby="customized-dialog-title"
            open={open}
            data-testid="click-to-buy"
            sx={{
              '@media (max-width: 500px)': {
                '& .MuiDialog-paper': {
                  margin: 0,
                },
              },
            }}
          >
            <DialogContent
              sx={{ minHeight: '50vh', minWidth: '50vw', padding: 0 }}
            >
              <Embed
                callbacks={ctbCallbacks}
                data={ctbData}
                isPending={isPending}
                product={product}
              />
            </DialogContent>
          </Dialog>
        </ThemeProvider>
      )}
    </>
  );
};

CTB.propTypes = propTypes;
export default CTB;
