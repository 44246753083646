import { isNumber } from 'lodash';
import { PropsWithChildren } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useSitesSecuritySslStatus } from '@newfold/huapi-js';
import {
  HostingSitesV2200ItemsItem,
  SiteInfoResponseModelV2,
} from '@newfold/huapi-js/src/index.schemas';

import useResponsive from '~/hooks/useResponsive';
import StatusChip from '~/scenes/Site/scenes/Cloud/components/StatusChip';
import { checkIsStagingSite } from '~/scenes/Site/scenes/Cloud/scenes/Staging/utils/checkIsStagingOrParentSite';
import WordPressIcon from '~/scenes/Sites/assets/icons/WordPressIcon';
import getSiteUrlWithoutProtocol from '~/utils/getSiteUrlWithoutProtocol';

import AllocationText from '../../../components/AllocationText';
import { PhpWorkersTooltip } from '../../../components/PhpWorkersTooltip';
import SiteCardActions from './SiteCardActions';

export default function SiteCard({
  site,
  ...props
}: PropsWithChildren<{
  site: HostingSitesV2200ItemsItem | SiteInfoResponseModelV2;
}>) {
  const workers = site?.resources?.workers;
  const storage = site?.resources?.storage;
  const siteStatus = site?.status!;
  const isStagingSite = checkIsStagingSite(site);
  const promotionStatus = site?.staging?.promotion_status;

  const { isMobile } = useResponsive();

  const {
    data,
    isError,
    isPending: isSslStatusLoading,
  } = useSitesSecuritySslStatus(Number(site.id), undefined, {
    query: { enabled: !!site.id && isStagingSite },
  });

  const siteSSLStatus = data?.data?.ssl_status;

  return (
    <Card variant="outlined" {...props}>
      <CardContent>
        <Stack spacing={{ xs: 2, sm: 1 }}>
          <Stack
            direction={'row'}
            spacing={2}
            justifyContent={'space-between'}
            alignItems={{ xs: 'flex-start', sm: 'center' }}
          >
            <Typography variant="h2">
              {site.name || site.url || 'WordPress Site'}
            </Typography>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
              {isStagingSite && promotionStatus && (
                <StatusChip type={'promotion'} status={promotionStatus} />
              )}
              {isStagingSite && !isError && !isSslStatusLoading && (
                <StatusChip type={'ssl'} status={siteSSLStatus} />
              )}
              <StatusChip status={siteStatus} />
            </Stack>
          </Stack>

          {site.url && (
            <Typography>
              {/* list as many domains as we can fit, then show "And +3 more" if unable to fit all */}
              <Link
                color="inherit"
                underline="none"
                href={site.url}
                target="_blank"
              >
                {getSiteUrlWithoutProtocol(site.url)}
              </Link>
            </Typography>
          )}

          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={2}
            justifyContent={'space-between'}
          >
            {/* meta */}
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={{ xs: 1, md: 2 }}
              alignItems={{ xs: 'flex-start', md: 'flex-end' }}
            >
              {/* wordpress version */}
              {site?.version && (
                <Stack alignContent={'center'} direction={'row'} spacing={0.5}>
                  {/* hide icon on mobile */}
                  {!isMobile() && <WordPressIcon />}
                  <Typography>WordPress</Typography>
                  <b>{site?.version || <Skeleton width={50} />}</b>
                </Stack>
              )}

              {/* php version */}
              {site?.php_version && (
                <Stack alignContent={'center'} direction={'row'} spacing={0.5}>
                  <Typography>PHP</Typography>
                  <b>{site?.php_version || <Skeleton width={20} />}</b>
                </Stack>
              )}

              {/* storage */}
              {
                /*TODO: uncomment when backend starts providing site storage used
                storage?.used &&*/
                storage?.total && (
                  <Stack
                    alignContent={'center'}
                    direction={'row'}
                    spacing={0.5}
                  >
                    {/* TODO: variablize GB units */}
                    <Typography>Storage</Typography>
                    <AllocationText
                      used={storage?.used}
                      total={storage?.total}
                      unit={storage?.unit}
                    />
                  </Stack>
                )
              }

              {/* php workers */}
              {isNumber(workers) && (
                <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                  <Typography>PHP Workers</Typography>
                  {/* mui tooltip using mui info icon */}
                  <PhpWorkersTooltip /> <b>{workers}</b>
                </Stack>
              )}
            </Stack>

            <SiteCardActions site={site} />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
