import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import WarningIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useSite } from '@newfold/huapi-js';

import { useAlert } from '~/components/Alerts/alertsStore';
import LoadingButtonWrapper from '~/components/LoadingButtonWrapper';

export type DeleteSiteModalProps = {
  open: boolean;
  setOpen: Function;
  siteUrl: string;
  siteId: number;
  siteName: string | undefined | null;
  parentSiteId?: number;
  isStagingSite?: boolean;
};

const DeleteSiteModal = ({
  setOpen,
  siteUrl,
  siteId,
  parentSiteId = undefined,
  siteName,
}: DeleteSiteModalProps) => {
  const { t } = useTranslation('cloud', {
    keyPrefix: 'settings.deleteSite.deleteModal',
  });

  const navigate = useNavigate();
  const generateAlert = useAlert();

  const { mutate: deleteSite, isPending } = useSite({
    mutation: {
      onSuccess: () => {
        generateAlert({
          severity: 'info',
          description: t('alertMessage.success', {
            siteName: siteName,
          }),
          showCloseBtn: true,
        });
        //navigate to hosting list if not a staging site.
        !!parentSiteId
          ? navigate(`/sites/${parentSiteId}/staging`)
          : navigate('/');
      },
      onError: () => {
        generateAlert({
          severity: 'error',
          description: t('alertMessage.error'),
          showCloseBtn: true,
        });
        setOpen(false);
      },
    },
  });

  const [isDeleteVerifiedByUserInput, setIsDeleteVerifiedByUserInput] =
    useState(false);

  const handleChange = (event: any) => {
    const deleteVal = t('deleteBtn');
    setIsDeleteVerifiedByUserInput(
      deleteVal.toUpperCase() === siteUrl.toUpperCase()
        ? event.target.value === siteUrl
        : siteUrl.toLowerCase() === event.target.value.toLowerCase(),
    );
  };

  const handleCancel = (event: any) => {
    setOpen(false);
  };

  const handleSubmit = (event: any) => {
    deleteSite({ siteId, data: {} });
  };

  return (
    <>
      <DialogTitle>
        <Typography variant="h2">
          {!!parentSiteId ? t('staging.title') : t('title')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Divider />
        <Stack direction="row" spacing={2} py={2}>
          <WarningIcon color="error" />
          <Typography component="div" fontWeight={'bold'}>
            {!!parentSiteId ? t('staging.desc') : t('desc.0')}
          </Typography>
        </Stack>
        <Grid container>
          <Grid item xs={12}>
            <Typography component="div" pb={3}>
              {t('desc.1')}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container my={3}>
          <Grid item xs={12}>
            <Typography component="div" variant="body1" pb={4}>
              <Trans
                t={t}
                i18nKey="confirmation"
                values={{ siteUrl }}
                components={{
                  SiteUrl: <Typography color="error" />,
                }}
              />
            </Typography>
            <TextField
              data-testid="confirmURL"
              id="outlined-basic"
              label={t('enterSiteUrl')}
              variant="outlined"
              fullWidth
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleCancel} disabled={isPending}>
          {t('cancelBtn')}
        </Button>
        <LoadingButtonWrapper
          data-testid="confirmDeleteBtn"
          variant="contained"
          color="error"
          disabled={!isDeleteVerifiedByUserInput}
          loading={isPending}
          onClick={handleSubmit}
        >
          {t('deleteBtn')}
        </LoadingButtonWrapper>
      </DialogActions>
    </>
  );
};

export default DeleteSiteModal;
