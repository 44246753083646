import { QueryClient, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useSitesInfo } from '@newfold/huapi-js';

import { useMFEContext } from '~/components/MFEProvider';
import useAccount from '~/hooks/useAccount';

type ProductCodeTypes =
  | 'HOSTING_UPGRADE'
  | 'SITELOCK_ESSENTIALS'
  | 'SITELOCK_FAMILY'
  | 'SITELOCK_UPGRADE'
  | 'YOAST_SEO_PREMIUM'
  | 'SSL_DV'
  | 'SSL_EV_FAMILY'
  | 'BH_ECOMM_ADDON_FAMILY'
  | 'CODEGUARD_FAMILY'
  | 'CODEGUARD_UPGRADE'
  | 'BH_ECOMM_ADDON_PLUS'
  | 'JETPACK_BACKUP_FAMILY'
  | 'JETPACK_BACKUP_T1'
  | 'WP_SOLUTION_FAMILY'
  | 'WP_SOLUTION_UPGRADE';

type ProductCodeTypesObject = {
  HOSTING_UPGRADE: 'HOSTING_UPGRADE';
  SITELOCK_ESSENTIALS: 'SITELOCK_ESSENTIALS';
  SITELOCK_FAMILY: 'SITELOCK_FAMILY';
  SITELOCK_UPGRADE: 'SITELOCK_UPGRADE';
  YOAST_SEO_PREMIUM: 'YOAST_SEO_PREMIUM';
  SSL_DV: 'SSL_DV';
  SSL_EV_FAMILY: 'SSL_EV_FAMILY';
  BH_ECOMM_ADDON_FAMILY: 'BH_ECOMM_ADDON_FAMILY';
  CODEGUARD_FAMILY: 'CODEGUARD_FAMILY';
  CODEGUARD_UPGRADE: 'CODEGUARD_UPGRADE';
  BH_ECOMM_ADDON_PLUS: 'BH_ECOMM_ADDON_PLUS';
  JETPACK_BACKUP_FAMILY: 'JETPACK_BACKUP_FAMILY';
  JETPACK_BACKUP_T1: 'JETPACK_BACKUP_T1';
  WP_SOLUTION_FAMILY: 'WP_SOLUTION_FAMILY';
  WP_SOLUTION_UPGRADE: 'WP_SOLUTION_UPGRADE';
};

export const productTypes = {
  HOSTING_UPGRADE: 'HOSTING_UPGRADE',
  SITELOCK_ESSENTIALS: 'SITELOCK_ESSENTIALS',
  SITELOCK_FAMILY: 'SITELOCK_FAMILY',
  SITELOCK_UPGRADE: 'SITELOCK_UPGRADE',
  YOAST_SEO_PREMIUM: 'YOAST_SEO_PREMIUM',
  SSL_DV: 'SSL_DV',
  SSL_EV_FAMILY: 'SSL_EV_FAMILY',
  BH_ECOMM_ADDON_FAMILY: 'BH_ECOMM_ADDON_FAMILY',
  CODEGUARD_FAMILY: 'CODEGUARD_FAMILY',
  CODEGUARD_UPGRADE: 'CODEGUARD_UPGRADE',
  BH_ECOMM_ADDON_PLUS: 'BH_ECOMM_ADDON_PLUS',
  JETPACK_BACKUP_FAMILY: 'JETPACK_BACKUP_FAMILY',
  JETPACK_BACKUP_T1: 'JETPACK_BACKUP_T1',
  WP_SOLUTION_FAMILY: 'WP_SOLUTION_FAMILY',
  WP_SOLUTION_UPGRADE: 'WP_SOLUTION_UPGRADE',
} as ProductCodeTypesObject;

const getQueryKey = (
  queryType: string,
  productCode: ProductCodeTypes,
  accountId?: string | number | null,
  productData?: { siteId?: string },
) => {
  return [`/ctb/${queryType}/${accountId}`, { productCode, ...productData }];
};

export const useCtbProductInfo = (productCode: ProductCodeTypes) => {
  const { t } = useTranslation('clickToBuy', { useSuspense: false });
  const { id: accountId } = useAccount();
  const {
    // @ts-expect-error
    data: { hostingData },
  } = useMFEContext();
  const { siteId } = useParams();

  const { data: siteInfo, isPending } = useSitesInfo(Number(siteId), {
    query: {
      enabled: false,
    },
  });

  const parentProdInstId =
    !isPending && !!siteInfo
      ? siteInfo?.data?.account_back_ref
      : hostingData?.productInstanceId;

  const hostingProductName = hostingData?.productName;

  const ctbProducts = {
    [productTypes.HOSTING_UPGRADE]: {
      data: {
        action: 'upgrade',
        prodInstId: parentProdInstId,
        adTrackingBannerId: 'P13C100S1N0B3003A1D115E0000V110',
      },
      name: hostingProductName,
      purchaseQueryKey: getQueryKey('hasPurchased', productCode, accountId),
    },
    [productTypes.BH_ECOMM_ADDON_FAMILY]: {
      data: {
        productCode: productTypes.BH_ECOMM_ADDON_FAMILY,
        parentProdInstId,
        adTrackingBannerId: 'P103C100S1N0B3003A151D115E0000V100',
      },
      name: t('productNames.ecommerce'),
      purchaseQueryKey: getQueryKey('hasPurchased', productCode, accountId),
    },
    [productTypes.BH_ECOMM_ADDON_PLUS]: {
      data: {
        action: 'upgrade',
        prodInstId: parentProdInstId,
        adTrackingBannerId: 'P103C100S1N0B3003A151D115E0000V101',
      },
      name: t('productNames.ecommerce'),
      purchaseQueryKey: getQueryKey('hasPurchased', productCode, accountId),
    },
    [productTypes.SITELOCK_ESSENTIALS]: {
      data: {
        productCode: productTypes.SITELOCK_ESSENTIALS,
        parentProdInstId,
        purchaseTerm: '1',
        purchaseTermUnit: 'YEAR',
        adTrackingBannerId: 'P13C100S1N0B3003A1D115E0000V108',
      },
      name: t('productNames.sitelock'),
      purchaseQueryKey: getQueryKey('hasPurchased', productCode, accountId),
    },
    [productTypes.SITELOCK_FAMILY]: {
      data: {
        productCode: productTypes.SITELOCK_FAMILY,
        parentProdInstId,
        adTrackingBannerId: 'P13C100S1N0B3003A1D115E0000V108',
      },
      name: t('productNames.sitelock'),
      purchaseQueryKey: getQueryKey('hasPurchased', productCode, accountId),
    },
    [productTypes.SITELOCK_UPGRADE]: {
      data: {
        action: 'upgrade',
        purchaseTerm: '1',
        purchaseTermUnit: 'YEAR',
        adTrackingBannerId: 'P13C100S1N0B3003A1D115E0000V108',
      },
      name: t('productNames.sitelock'),
      purchaseQueryKey: getQueryKey('hasPurchased', productCode, accountId),
    },
    [productTypes.YOAST_SEO_PREMIUM]: {
      data: {
        productCode: productTypes.YOAST_SEO_PREMIUM,
        parentProdInstId,
        adTrackingBannerId: 'P167C100S1N0B3003A151D115E0000V100',
        // { parentProduct: { siteId } } // also required, but should be passed in from current UI with data prop
      },
      name: t('productNames.yoast'),
      purchaseQueryKey: getQueryKey('hasPurchased', productCode, accountId, {
        siteId,
      }),
    },
    [productTypes.SSL_DV]: {
      data: {
        productCode: productTypes.SSL_DV,
        adTrackingBannerId: 'P13C100S1N0B3003A151D115E0000V104',
      },
      name: t('productNames.ssl'),
      purchaseQueryKey: getQueryKey('hasPurchased', productCode, accountId, {
        siteId,
      }),
    },
    [productTypes.SSL_EV_FAMILY]: {
      data: {
        productCode: productTypes.SSL_EV_FAMILY,
        adTrackingBannerId: 'P90C100S1N0B3003A20D115E0000V100',
      },
      name: t('productNames.ssl'),
      purchaseQueryKey: getQueryKey('hasPurchased', productCode, accountId, {
        siteId,
      }),
    },
    [productTypes.CODEGUARD_FAMILY]: {
      data: {
        productCode: productTypes.CODEGUARD_FAMILY,
        parentProdInstId,
        adTrackingBannerId: 'P13C100S1N0B3003A1D115E0000V109',
      },
      name: t('productNames.codeguard'),
      purchaseQueryKey: getQueryKey('hasPurchased', productCode, accountId),
    },
    [productTypes.CODEGUARD_UPGRADE]: {
      data: {
        action: 'upgrade',
        // purchaseTerm: '1', // Is this needed?
        purchaseTermUnit: 'YEAR',
        adTrackingBannerId: 'P180C100S1N0B3003A1D115E0000V100',
      },
      name: t('productNames.codeguard'),
      purchaseQueryKey: getQueryKey('hasPurchased', productCode, accountId),
    },
    [productTypes.JETPACK_BACKUP_FAMILY]: {
      data: {
        productCode: productTypes.JETPACK_BACKUP_FAMILY,
        parentProdInstId,
        adTrackingBannerId: 'P13C100S1N0B3003A1D115E0000V111',
        // parentProduct: siteId, // also required, but should be passed in from current UI with data prop
      },
      name: t('productNames.jetpack'),
      purchaseQueryKey: getQueryKey('hasPurchased', productCode, accountId, {
        siteId,
      }),
    },
    [productTypes.JETPACK_BACKUP_T1]: {
      data: {
        action: 'upgrade',
        //prodInstId: //Required - HAL addon backreference Id - passed in from current UI with data prop
        adTrackingBannerId: 'PP13C100S1N0B3003A151D115E0000V106',
      },
      name: t('productNames.jetpack'),
      purchaseQueryKey: getQueryKey('hasPurchased', productCode, accountId, {
        siteId,
      }),
    },
    // WP_SOLUTION_FAMILY CTB
    [productTypes.WP_SOLUTION_FAMILY]: {
      data: {
        productCode: productTypes.WP_SOLUTION_FAMILY,
        parentProdInstId,
        adTrackingBannerId: 'P195C100S1N0B3003A151D115E0000V100',
        parentProduct: { siteId },
      },
      name: t('productNames.wpSolution'),
      purchaseQueryKey: getQueryKey('hasPurchased', productCode, accountId, {
        siteId,
      }),
    },
    // WP_SOLUTION_UPGRADE CTB
    [productTypes.WP_SOLUTION_UPGRADE]: {
      data: {
        action: 'upgrade',
        adTrackingBannerId: 'P195C100S1N0B3003A151D115E0000V100',
      },
      name: t('productNames.wpSolution'),
      purchaseQueryKey: getQueryKey('hasPurchased', productCode, accountId, {
        siteId,
      }),
    },

    // FUTURE PRODUCTS HERE...
    // [PRODUCT_TYPES.YOAST_SEO]: {},
  };

  return ctbProducts[productCode];
};

type QueryKeyType = (
  | string
  | {
      siteId?: string;
      productCode: ProductCodeTypes;
    }
)[];
export const useCtbPurchaseStatusQuery = (queryKey: QueryKeyType) => {
  const result = useQuery({
    queryKey,
    queryFn: () => {
      return { hasPurchasedRecently: false };
    },
    enabled: true,
    staleTime: 1000 * 60 * 10, // fresh data can be fetched after 10 minutes
    refetchInterval: 1000 * 60 * 10,
  });

  const hasPurchasedRecently =
    result.isPending || result.isRefetching
      ? false
      : result?.data?.hasPurchasedRecently;

  return { ...result, hasPurchasedRecently };
};

/* When the product info query from HUAPI eventually becomes available,
  we'll call it in the queryFn above and remove setCtbPurchaseQueryData entirely */
export const setCtbPurchaseQueryData = (
  queryClient: QueryClient,
  queryKey: QueryKeyType,
  data: {
    hasPurchasedRecently: boolean;
  },
) => {
  queryClient.setQueryData(queryKey, data);
};
