import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { useHostingInfoDiskUsage } from '@newfold/huapi-js';

import { useMFEContext } from '~/components/MFEProvider';
import { useBrandInfo } from '~/hooks/useBrandInfo';
import useFeature from '~/hooks/useFeature';
import useHostingInfo from '~/hooks/useHostingInfo';
import useScrollToLocation from '~/hooks/useScrollToLocation';
import { getUsageStats } from '~/utils/usage-stats';

import DiskUsageAlert from './DiskUsageAlert';

export default function AlertBanner() {
  const { t } = useTranslation('hosting');

  const navigate = useNavigate();
  const {
    data: { hostingData, renewalCenterLink },
    callbacks: { handleClickToBuy },
    hostingStatus: {
      renewalStatus: { isExpired, willExpireSoon },
    },
  } = useMFEContext() as { data: any; hostingStatus: any; callbacks: any };
  useScrollToLocation();

  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const { supportLink } = useBrandInfo();
  const [isPlesk] = useFeature('plesk');
  const [isReseller] = useFeature('reseller');
  const { isSuspended, isDecommissioned, hostingId } = useHostingInfo();

  const hasAutoRenew = hostingData?.hasAutoRenew;
  const hostingPlan = hostingData?.productName;

  const { siteId } = useParams();
  const { data: usageData } = useHostingInfoDiskUsage(hostingId, {
    query: {
      enabled: !!hostingId && (!isExpired || !isSuspended) && !isPlesk && !isReseller,
      select: ({ data }) => getUsageStats(data),
    },
  });

  // @ts-expect-error
  const alertState = usageData?.alertState;

  const handleClickForUsage = () => {
    const element = document.getElementById('usage-stats');

    if (siteId) {
      return navigate(`/sites/${siteId}/settings#usage-stats`);
    }

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const handleExternalLink = async () => {
    if (handleClickToBuy?.HOSTING_RENEW) {
      setIsBtnLoading(true);
      await handleClickToBuy.HOSTING_RENEW();
      setIsBtnLoading(false);
      return;
    }

    window.location.assign(renewalCenterLink);
  };

  if (isSuspended) {
    return (
      <Alert data-testid="alert-banner" severity="error">
        <AlertTitle>{t(`suspended.alert.title`, { hostingPlan })}</AlertTitle>
        <Typography>
          <Trans
            i18nKey="hosting:suspended.alert.description"
            components={{
              Link: <Link href={supportLink} target="_blank" />,
            }}
          />
        </Typography>
      </Alert>
    );
  }
  if ((isExpired && !hasAutoRenew) || (isDecommissioned && !hasAutoRenew)) {
    return (
      <Alert
        data-testid="alert-banner"
        severity="error"
        action={
          <Button onClick={handleExternalLink} disabled={isBtnLoading}>
            {t('renewal.alerts.ctaText')}
          </Button>
        }
      >
        <AlertTitle>
          {t(`renewal.alerts.isExpired.title`, { hostingPlan })}
        </AlertTitle>
        {t(`renewal.alerts.isExpired.description`)}
      </Alert>
    );
  }
  if ((isExpired && hasAutoRenew) || (isDecommissioned && hasAutoRenew)) {
    return (
      <Alert
        data-testid="alert-banner"
        severity="error"
        action={
          <Button onClick={handleExternalLink} disabled={isBtnLoading}>
            {t('renewal.alerts.ctaText')}
          </Button>
        }
      >
        <AlertTitle>
          {t(`renewal.alerts.autoRenewFailed.title`, { hostingPlan })}
        </AlertTitle>
        {t(`renewal.alerts.autoRenewFailed.description`)}
      </Alert>
    );
  }

  if (willExpireSoon && !hasAutoRenew) {
    return (
      <Alert
        data-testid="alert-banner"
        severity="error"
        action={
          <Button onClick={handleExternalLink} disabled={isBtnLoading}>
            {t('renewal.alerts.ctaText')}
          </Button>
        }
      >
        <AlertTitle>
          {t(`renewal.alerts.willExpireSoon.title`, { hostingPlan })}
        </AlertTitle>
        {t(`renewal.alerts.willExpireSoon.title`, { hostingPlan })}
      </Alert>
    );
  }
  if (alertState && !isReseller) {
    return (
      <DiskUsageAlert
        alertState={alertState as string}
        handleClick={handleClickForUsage}
      />
    );
  }

  return null;
}
